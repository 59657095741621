import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import E404 from './pages/E404';
import Envoye from './pages/Envoye';
import Erreur from './pages/Erreur';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Succes' element={<Envoye />} />
        <Route path='/erreur' element={<Erreur />} />
        <Route path='*' element={<E404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
