import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="text-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="max-lg:flex max-lg:justify-center lg:flex lg:items-center lg:justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <NavLink to="/" className="text-xl -m-1.5 p-1.5">
              <img src="/img/logo-big.svg" className="w-48 max-lg:w-64" />
            </NavLink>
          </div>
          <div className="flex lg:hidden"></div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a
              href="#services"
              className="text-sm font-semibold leading-6 text-white"
            >
              Nos Services
            </a>
            <a
              href="#infos"
              className="text-sm font-semibold leading-6 text-white"
            >
              Notre Entreprise
            </a>
            <a
              href="#mot"
              className="text-sm font-semibold leading-6 text-white"
            >
              Notre Expertise
            </a>
            <a
              href="#contact"
              className="text-sm font-semibold leading-6 text-white"
            >
              Nous Contacter
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#contact"
              className="rounded-md bg-white px-3.5 p-2 py-2.5 text-sm font-semibold text-[#422815] hover:bg-gray-500 shadow-sm focus-visible:outline focus-visible:outline-2"
            >
              <i class="fa-solid fa-paperclip"></i> DEVIS GRATUIT
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
}
