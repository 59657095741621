import { useNavigate } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import Navbar from "../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

AOS.init();

const Home = () => {
  const form = useRef();
  const navigate = useNavigate();
  const sendmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uqo5gaq",
        "template_75kuqz4",
        form.current,
        "jpiy2QiK615ULP3fY"
      )
      .then(
        (result) => {
          console.log(result.text);
          navigate("/Succes");
        },
        (error) => {
          console.log(error.text);
          navigate("/Erreur");
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <div>
        <Navbar />
        <div className="w-full h-screen bg-cover bg-center bg-bgb">
          <div className="backdrop-blur-lg flex justify-center items-center h-screen">
            <div className="max-xl:w-[90%] w-[40%]">
              <h1
                className="text-center text-4xl font-bold tracking-tight text-white sm:text-6xl"
                data-aos="zoom-in"
              >
                LD Création <br />
                <span>
                  <Typewriter
                    words={[
                      "Maçonnerie Paysagère",
                      "Entretien Des Espaces Verts",
                      "Engazonnement",
                      "Plantation",
                      "Terrassement",
                      "Clôtures",
                      "Robotisation",
                      "Arrosage",
                    ]}
                    loop={9999999999999999999999}
                    cursor
                    cursorStyle="|"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </span>
              </h1>
              <p
                className="text-center mt-6 text-lg leading-8 text-white"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                Transformez vos rêves en réalité pour vos espaces extérieurs
                avec l'expertise LD Création. Notre créativité et savoir-faire
                remarquable seront au service de vos projets
              </p>
              <p
                className="text-center mt-8"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="600"
              >
                <a
                  href="#services"
                  className="rounded-md bg-[#fff] p-2 px-3.5 py-2.5 text-sm font-semibold text-[#422815] shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2"
                >
                  <i class="fa-solid fa-trowel text-[#422815]"></i> Nos Services
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          className="text-white bg-bg-g w-full bg-cover bg-center"
          id="services"
        >
          <div className="backdrop-blur-lg">
            <div>
              <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
                <div className="mx-auto max-w-lg text-center">
                  <h2
                    className="text-3xl font-bold sm:text-4xl"
                    data-aos="zoom-in"
                  >
                    Nos Services
                  </h2>
                  <p className="mt-4 text-gray-300" data-aos="zoom-in">
                    Une liste des services que nous proposons
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:shadow-[#fff]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <img src="/img/1.jpg" className="h-10" alt="" />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Maçonnerie Paysagère
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Transformez vos espaces extérieurs avec notre expertise en
                      maçonnerie paysagère. Des créations uniques qui
                      sublimeront votre environnement
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:shadow-[#fff]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <img src="/img/4.png" className="h-10" alt="" />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Terrassement
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Façonnez le terrain selon vos projets avec notre service
                      de terrassement. Une expertise solide pour des fondations
                      robustes.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:shadow-[#fff]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <img src="/img/3.jpg" className="h-10" alt="" />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Engazonnement Et Plantation
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Donnez vie à vos espaces avec notre service
                      d'engazonnement et de plantation. Une touche naturelle qui
                      transformera votre environnement.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:shadow-[#fff]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <img src="/img/2.jpg" className="h-10" alt="" />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Entretien Des Espaces Verts
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Confiez-nous l'harmonie de vos jardins. Notre service
                      d'entretien des espaces verts assure la beauté et la
                      vitalité de vos aménagements extérieurs.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:shadow-[#fff]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <img src="/img/6.png" className="h-10" alt="" />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Clôtures
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Sécurisez et embellissez votre propriété avec nos
                      solutions de clôtures. Alliez fonctionnalité et esthétique
                      pour délimiter vos espaces avec style.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:shadow-[#fff]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <img src="/img/5.png" className="h-10" alt="" />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Robotisation Et Arrosage
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Simplifiez l'entretien de votre jardin avec nos solutions
                      de robotisation et d'arrosage. Une automatisation
                      intelligente pour un jardin toujours éclatant.
                    </p>
                  </a>
                </div>
                <div className="mt-12 text-center">
                  <a
                    href="#contact"
                    className="p-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-[#422815] shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    Nous Contacter{" "}
                    <i class="fa-solid fa-arrow-right text-[#422815]"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8 sm:py-12" id="infos">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Notre Entreprise
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              Découvrez l'Essence de Notre Entreprise
            </p>
            <div
              className="mt-12 max-lg:mt-8 xl:flex justify-center xl:items-center"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <img
                src="/img/logo2.svg"
                className="xl:mr-28 max-sm:w-64 w-96 cloudeffect max-xl:mx-auto max-xl:py-8"
              />
              <div className="grid divide-y divide-neutral-200 max-w-xl max-sm:w-64 max-xl:mx-auto">
                <p className="text-xl max-sm:text-center">
                  LD Création, votre partenaire privilégié pour des espaces
                  extérieurs d'exception ! Spécialisés en maçonnerie paysagère,
                  entretien des espaces verts, engazonnement, plantation,
                  terrassement, clôtures, robotisation et arrosage, nous allions
                  créativité et expertise pour transformer vos idées en réalité.
                  Confiez-nous votre projet, et bénéficiez d'un savoir-faire
                  remarquable pour des espaces extérieurs harmonieux et
                  fonctionnels.
                </p>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8 sm:py-12" id="mot">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Le Mot du Patron
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              L'inspiration et la vision qui guident notre entreprise vers
              l'excellence
            </p>
            <div
              className="mt-12 max-lg:mt-8 xl:flex justify-center xl:items-center"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <div className="grid divide-y divide-neutral-200 max-w-xl max-sm:w-64 max-xl:mx-auto">
                <p className="text-xl max-sm:text-center">
                  En tant que fondateur de notre entreprise, je suis fier de
                  mettre à profit mes connaissances et mon expertise acquises
                  lors de l'obtention de mon BTSA Aménagements Paysagers. Avec
                  une approche professionnelle et créative, notre équipe
                  s'engage à créer des espaces extérieurs uniques et harmonieux,
                  répondant à vos besoins et à vos aspirations. Nous
                  transformons vos idées en réalité, en concevant des jardins et
                  des espaces verts qui reflètent votre style de vie et votre
                  environnement.
                </p>
              </div>
              <img
                src="/img/user.svg"
                className="xl:ml-28 max-sm:w-64 w-82 cloudeffect max-xl:mx-auto max-xl:py-8"
              />
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg pt-12" id="contact">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Nous Contacter
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              Joignez-nous Facilement
            </p>
            <div
              className="mx-auto max-w-screen-xl px-4 pt-8 pb-16 sm:px-6 lg:px-8"
              data-aos="fade-up"
            >
              <div className="rounded-lg bg-white p-8 text-black shadow-lg lg:col-span-3 lg:p-12">
                <form ref={form} onSubmit={sendmail} className="space-y-4">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="sr-only">Nom</label>
                      <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Nom"
                        type="text"
                        name="nom"
                        required
                      />
                    </div>
                    <div>
                      <label className="sr-only">Prénom</label>
                      <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Prénom"
                        type="text"
                        name="prenom"
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="sr-only">Email</label>
                      <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Email"
                        type="email"
                        name="email"
                        required
                      />
                    </div>
                    <div>
                      <label className="sr-only">Téléphone</label>
                      <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm"
                        placeholder="Téléphone"
                        type="tel"
                        name="tel"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="sr-only">Sujet</label>
                    <input
                      className="w-full rounded-lg border-gray-200 p-3 text-sm"
                      placeholder="Sujet"
                      type="text"
                      name="sujet"
                      required
                    />
                  </div>
                  <div>
                    <label className="sr-only">Message</label>
                    <textarea
                      className="w-full rounded-lg border-gray-200 p-3 text-sm"
                      placeholder="Message"
                      rows="8"
                      name="message"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="inline-block w-full rounded-lg bg-[#422815] hoover:bg-[#25371F] px-5 py-3 font-medium text-white sm:w-auto"
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          <footer className="backdrop-blur-lg">
            <div className="mx-auto max-w-5xl px-4 py-8 sm:px-6 lg:px-8">
              <div className="flex justify-center">
                <img
                  src="/img/logo-big.svg"
                  className="w-64"
                  alt="LD Création's logo"
                />
              </div>
              <a href="https://www.google.com/maps/place/10+Rue+de+l'Aspirant+Girard,+68126+Kaysersberg/@48.1340965,7.3016229,17z/data=!3m1!4b1!4m6!3m5!1s0x4791672ef1389be3:0x3104848f87711d5e!8m2!3d48.1340929!4d7.3041978!16s%2Fg%2F11cpmg2h3y?entry=ttu">
                <p className="mx-auto mt-4 max-w-md text-center leading-relaxed text-white">
                  <i class="fa-solid fa-location-dot"></i> 10 rue de l’Aspirant
                  Girard, 68240 Kaysersberg Vignoble
                </p>
              </a>
              <a
                href="mailto:louisdietrich@ldcreation.info"
                rel="noreferrer"
                target="_blank"
              >
                <p className="mx-auto mt-2 max-w-md text-center leading-relaxed text-white">
                  <i class="fa-solid fa-envelope"></i>{" "}
                  louisdietrich@ldcreation.info
                </p>
              </a>
              <a href="tel:+33782766639" rel="noreferrer" target="_blank">
                <p className="mx-auto mt-2 max-w-md text-center leading-relaxed text-white">
                  <i class="fa-solid fa-phone"></i> 07 82 76 66 39
                </p>
              </a>
              <ul className="mt-8 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-6">
                <li>
                  <a
                    className="text-white transition hover:text-white/75"
                    href="#services"
                  >
                    {" "}
                    Nos Services{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="text-white transition hover:text-white/75"
                    href="#infos"
                  >
                    {" "}
                    Notre Entreprise{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="text-white transition hover:text-white/75"
                    href="#contact"
                  >
                    {" "}
                    Nous Contacter{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="text-white transition hover:text-white/75"
                    href="/legal.pdf"
                  >
                    {" "}
                    Legal{" "}
                  </a>
                </li>
              </ul>
              <ul className="mt-6 flex justify-center gap-6 md:gap-8">
                <li>
                  <a
                    href="https://www.instagram.com/ldcreationfr/"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white transition hover:text-white/75"
                  >
                    <span className="sr-only">Instagram</span>
                    <i class="fa-brands fa-instagram fa-xl"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.threads.net/@ldcreationfr"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white transition hover:text-white/75"
                  >
                    <span className="sr-only">Threads</span>
                    <i class="fa-brands fa-threads fa-xl"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://fr.linkedin.com/in/louis-dietrich-20227b158?trk=people-guest_people_search-card"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white transition hover:text-white/75"
                  >
                    <span className="sr-only">Linkedin</span>
                    <i class="fa-brands fa-linkedin fa-xl"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@LDCreationFR"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white transition hover:text-white/75"
                  >
                    <span className="sr-only">YouTube</span>
                    <i class="fa-brands fa-youtube fa-xl"></i>
                  </a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Home;
